// import classNames from 'classnames'
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classes from "./index.module.scss"

function MarketoForm({ marketo_id, thank_you_message}) {

  const [showThankYou, setShowThankYou] = useState(false);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      typeof window.MktoForms2 !== "undefined"
    ) {
      window.MktoForms2.loadForm(
        "//app-sj21.marketo.com",
        "336-BHM-695",
        marketo_id,
        form => {
          if (form) {
            form.onSuccess(() => {
              setShowThankYou(true)
              return false
            })
          }
        }
      )
    }
  }, [marketo_id])

  return (
    <div className={classes.form__Wrapper}>
      {showThankYou ? (
        <p>{thank_you_message}</p>
      ) : (
        <form id={`mktoForm_${marketo_id}`} />
      )}
    </div>
  )
}

MarketoForm.defaultProps = {
    thank_you_message: "",
}

MarketoForm.propTypes = {
    marketo_id: PropTypes.string.isRequired,
    // thank_you_title: PropTypes.string,
    thank_you_message: PropTypes.string,

}
export default MarketoForm

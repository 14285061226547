import React from "react"
// import ReactDOM from "react-dom"
import { Layout, SEO } from "../../components"
import Marketo from "../../components/marketo-form"
import Modal from "react-modal"
import { validateArray } from "../../components/utilities/functions"
import styles from "./index.module.scss"

const IndexPage = props => {
  const { pageContext } = props
  const {
    uid = "",
    locale = "",
    url,
    seo = {},
    heading,
    subheading,
    table_heading,
    table_rows,
    table_alignment,
    subscribe,
  } = pageContext.node || {}

  const customStyles = {
    content: {
      top: "100px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      //   marginRight: "-50%",
      transform: "translateX(-50%)",
      overflow: "auto",
      maxHeight: " 100%",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.8)",
      zIndex: 999,
    },
  }

  seo.title = (seo && seo.title) || heading

  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false)
  }

  const extension = {
    contenttype: pageContext?.contentType || "",
    uid: uid,
    locale: locale,
  }

  return (
    <Layout pageURL={url} className="subprocessors-page">
      <SEO pageURL={url} {...seo} extension={extension} />
      <div className={"container " + styles.container}>
        {/* Top Row */}
        <div className={"row " + styles.top__Row}>
          <div className="col-12">
            {heading && <h1 className={styles.heading}>{heading}</h1>}
            {subheading && <h2 className={styles.subheading}>{subheading}</h2>}

          {subscribe?.link_title && (
            <>
              <span className={styles.link} onClick={openModal}>
                {subscribe.link_title}
              </span>
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <button className={styles.modal__Close} onClick={closeModal}>
                  x
                </button>
                <h4 className={styles.modal_heading}>Subscribe to get an update when our sub-processor list is revised.</h4>
                <Marketo
                  thank_you_message={
                    subscribe.thank_you_message || "Thank you for subscribing to the Raw Engineering Sub-processor list. You will receive an email when ever the list is updated"
                  }
                  marketo_id={subscribe.form_id}
                />
              </Modal>
            </>
          )}
		  </div>
        </div>

        <div className={styles.tableWrapper}>
          <table
            className={`${styles.detailsTable} ${
              table_alignment ? " text-" + table_alignment : " "
            }`}
          >
            <tbody>
              <tr>
                {validateArray(table_heading) &&
                  table_heading.map((item, index) => (
                    <th key={index}>{table_heading[index] || ""}</th>
                  ))}
              </tr>
              {validateArray(table_rows) &&
                table_rows.map((item, index) => (
                  <tr key={index}>
                    {["vendor", "role", "support_email_url", "location"].map(
                      (igKey, id) => {
                        return (
                          <td
                            key={id}
                            dangerouslySetInnerHTML={{ __html: item[igKey] }}
                          />
                        )
                      }
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
